<template>
  <div class="customer-authenticate mt-32">
    <div class="ds-wrapper mt-3 md:mt-10 lg:mt-10 pt-4 md:pt-6 lg:pt-8">
      <div class="rounded-3xl bg-container-40 px-6 sm:px-8 pb-8 sm:pb-10 text-center sm:w-3/5 md:w-4/12 mx-auto">
        <p class="py-6 para-def-light">Your details are secured with a one-time code</p>
        <p class="text-primary mb-6">Enter the 6-digit code we sent to <br />
        {{mobileOrEmail}}</p>
        <div class="flex justify-center">
          <div class="otp-input-wrap mb-2">
            <v-otp-input
              ref="otpInput1"
              input-classes="otp-input"
              separator=""
              :num-inputs="6"
              :is-input-num="true"
              :should-auto-focus="true"
              input-type="number"
              @on-change="onNumberChange"
              @paste.native="pasteNumber"
              :class="{'input-validate-danger': isOtpInvalid }"
            />
            <div v-if="isOtpInvalid" class="span-text-validation-danger flex"><span class="span-text-validation">Invalid code. Please try again</span></div>
          </div>
        </div>
        <p class="text-sm mb-10">Didn&apos;t get it? 
          <span class="text-interaction-primary cursor-pointer" @click="resendCode">Resend code</span>
        </p>
        <vs-button color="primary" size="large" class="w-full max-w-400" @click="verifyCustomer" :disabled="disabled">Verify</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
  import CodeInput from "vue-verification-code-input";
  import OtpInput from "@bachdgvn/vue-otp-input";
  import { mapActions } from "vuex";

  export default {
    components: {
      CodeInput,
      'v-otp-input': OtpInput,
    },
    data() {
      return {
        isMobileOrEmail: "",
        prId: this.$route.params.prId,
        verificationCode: "",
        mobileOrEmail: "",
        isOtpInvalid: false,
        isInvalidCodce: true,
      };
    },
    methods: {
      ...mapActions("paymentRequest", ["verifyCode", "sendCode"]),
      onNumberChange(verificationCode) {
        this.verificationCode = verificationCode;
      },
      verifyCustomer() {
        let payload = {
          prId: this.prId,
          verificationCode: this.verificationCode,
        };
        this.$vs.loading();
        this.verifyCode(payload)
          .then((res) => {
            this.$router.push({name:"paymentRequestDetail", params: {prId: this.prId}});
            this.$vs.loading.close();
          })
          .catch((err) => {
            this.isOtpInvalid = true;
            this.$vs.loading.close();
          });
      },
      resendCode() {
        let { isMobileOrEmail, prId } = this;
        this.sendCode({ isMobileOrEmail, prId }).then((res) => {});
      },
      pasteNumber(a){
        this.verificationCode = a.clipboardData.getData('text')
      }
    },
    computed: {
      disabled() {
        if (!this.verificationCode) return true;
        if (this.verificationCode.length != 6) {
          return true;
        }
        return false;
      },
    },
    created() {
      this.isMobileOrEmail = localStorage.getItem("isMobileOrEmail");
      this.mobileOrEmail = localStorage.getItem("mobileOrEmail");
      if (!this.isMobileOrEmail) {
        this.$router.push({ name: "Authenticate", params: { prId: this.prId } });
      }
    },
  };
</script>